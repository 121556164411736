<template>
  <section class="section">
    <div class="container">
      <div class="row items">
        <div class="col-12">
          <div class="wrapp-page-title page-title-center">
            <h1 class="page-title">{{title}}</h1>
            <div class="case-study-tags">
              <i class="fa fa-tag pr-2"></i>
              <a href="#" class="tag pr-4">Web application</a>
              <i class="fa fa-tag pr-2"></i>
              <a href="#" class="tag pr-4">B2B</a>
              <i class="fa fa-tag pr-2"></i>
              <a href="#" class="tag">Business tool</a>
            </div>
          </div>
        </div>
        <div class="col-12 item">
          <div id="app">
            <agile class="main" ref="main" :options="options1" :as-nav-for="asNavFor1">
              <div
                class="slide"
                v-for="(slide, index) in slides"
                :key="index"
                :class="`slide--${index}`"
              >
                <img :src="slide" />
              </div>
            </agile>
            <agile class="thumbnails" ref="thumbnails" :options="options2" :as-nav-for="asNavFor2">
              <div
                class="slide slide--thumbniail"
                v-for="(slide, index) in slides"
                :key="index"
                :class="`slide--${index}`"
                @click="$refs.thumbnails.goTo(index)"
              >
                <img :src="slide" />
              </div>

              <template slot="prevButton">
                <i class="fa fa-chevron-left"></i>
              </template>
              <template slot="nextButton">
                <i class="fa fa-chevron-right"></i>
              </template>
            </agile>
          </div>
        </div>
        <div class="col-12 item">
          <div class="project-info">
            <h4>Details</h4>
            <div class="project-info-row">
              <div class="project-info-label">Final budget:</div>
              <div class="project-info-desc">$10,000,000</div>
            </div>
            <div class="project-info-row">
              <div class="project-info-label">Contract type:</div>
              <div class="project-info-desc">Management</div>
            </div>
            <div class="project-info-row">
              <div class="project-info-label">Owner:</div>
              <div class="project-info-desc">Google</div>
            </div>
            <div class="project-info-row">
              <div class="project-info-label">Location:</div>
              <div class="project-info-desc">Albuquerque, MI</div>
            </div>
          </div>
        </div>
        <div class="col-12 item">
          <div class="content project-content">
            <p>We are an independent travel agency offering high quality and competitively priced holidays.</p>
            <p>If you can’t find what you’re looking for on-line just give us a call and we’ll do the searching for you.</p>
            <p>Whether it’s a late deal to the sun or the Honeymoon of a lifetime our expert team are standing by to help.</p>
            <p>All our holidays are ATOL Protected for your financial peace of mind.</p>

            <h2>Title h2</h2>
            <ul>
              <li>Working from home meant we could vary snack and coffee breaks</li>
              <li>Сhange our desks or view</li>
              <li>Вrink on the job</li>
              <li>Meet to gossip or share ideas</li>
              <li>Ourselves around, set impossible goals</li>
            </ul>
            <h3>Title h3</h3>
            <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aliquid perspiciatis labore beatae tempora cumque atque ducimus in sed, doloremque repudiandae ut necessitatibus, non quaerat autem magni pariatur excepturi reiciendis quis? Lorem ipsum, dolor sit amet consectetur adipisicing elit. Error, debitis aperiam! Fugiat rem odio omnis eaque voluptas doloribus, molestiae non reiciendis pariatur totam ad quasi, velit earum eos inventore ipsum? Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corrupti, nisi cupiditate eius veniam voluptatem provident consequatur nostrum facere, sed ut numquam eveniet ipsum quaerat corporis labore laboriosam pariatur? Minima, perferendis.</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <!-- Begin our customers -->
          <section class="section">
            <div class="container">
              <div class="row items">
                <div class="col-12">
                  <div class="wrapp-section-title section-title-center">
                    <div class="section-subtitle">The best</div>
                    <h2 class="section-title">Recent Projects</h2>
                  </div>
                </div>
              </div>
              <projects />
            </div>
          </section>
          <!-- End our customers -->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { VueAgile } from "vue-agile";

export default {
  name: "Goldentickettravel",
  data() {
    return {
      title: "Peter Goord Travel",
      asNavFor1: [],
      asNavFor2: [],
      options1: {
        dots: false,
        fade: true,
        navButtons: false
      },

      options2: {
        autoplay: true,
        centerMode: true,
        dots: false,
        navButtons: false,
        slidesToShow: 3,
        responsive: [
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 5
            }
          },

          {
            breakpoint: 1000,
            settings: {
              navButtons: true
            }
          }
        ]
      },
      slides: [
        "/img/projects/goldentickettravel/image3.png",
        "/img/projects/goldentickettravel/image1.jpg",
        "/img/projects/goldentickettravel/image2.png"
      ]
    };
  },
  mounted() {
    this.asNavFor1.push(this.$refs.thumbnails);
    this.asNavFor2.push(this.$refs.main);
  },
  components: {
    agile: VueAgile
  }
};
</script>

<style>
</style>